* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.form-container {
  margin-top: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 0.8rem;
}
.form-container h2 {
  margin-bottom: -1rem;
}
.signup-button {
  background-color: hsl(39, 77%, 53%);
  height: 2.5rem;
  border-radius: 2rem;
}
